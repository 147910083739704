import { ApiMethods } from "../enum/ApiMethods";
import ApiHelper from "./ApiHelper";

const api = require("../../../../storage/app/apiPath/user.json");



export default class LoginApiHelper {

    public static submitBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, api.basicInfo, params);
    }
    public static modifyBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.PUT, api.basicInfo, params);
    }
    public static submitAnswer(params: Object) {
        return ApiHelper.callSelf(ApiMethods.POST, api.answer, params);
    }
    public static getRecords(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.records, params);
    }
    public static getBasicInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.basicInfo, params);
    }
    public static getUserData(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.userData, params);
    }
    public static getQuestion(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.question, params);
    }
    public static getExtraInfo(params: Object) {
        return ApiHelper.callSelf(ApiMethods.GET, api.extraInfo, params);
    }
    public static logout() {
        return ApiHelper.callSelf(ApiMethods.POST, api.logout);
    }
}