<template>
  <!-- 測驗結果頁面 -->
  <div class="py-3">
    <div class="row">
      <Collapse v-model="openPanel" class="w-100">
        <Panel name="basic">
          <label class="textSeparate button">{{
            $t("result_subject_basic_info")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <div
            class="colorMain border-radius"
            slot="content"
            style="position: relative"
          >
            <Spin size="large" fix v-if="basicInfoLoading"></Spin>
            <result-basic-info :basicInfo="basicInfo" />
          </div>
        </Panel>
        <Panel
          name="unlucky_info"
          v-if="
            showInfo('unlucky_info') ||
            showInfo('unlucky_info_3years') ||
            showInfo('unlucky_info_5years')
          "
        >
          <label class="textSeparate button">{{
            $t("result_subject_unlucky_info")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <div v-if="Object.keys(yearsData).length > 0" slot="content">
            <div
              class="colorMain border-radius pt-4"
              style="position: relative; overflow: hidden"
            >
              <div v-if="unluckyYears.length > 1" style="position: relative">
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    z-index: 1;
                    text-align: center;
                  "
                >
                  <div
                    v-for="year in unluckyYears"
                    :key="year"
                    class="button"
                    :style="`height: 100%;
                    width:${100 / unluckyYears.length}%;    
                    display: flex;
                    flex-direction: column;`"
                    @click="selectYear(year)"
                    :selected="currentYear == year"
                  >
                    <div class="unluckyYearsHeader">{{ year }}</div>
                    <div class="unluckyYearsContent flex-grow-1 h-0"></div>
                  </div>
                </div>
                <apexchart
                  style="padding-top: 15px"
                  type="area"
                  :options="unluckyChartOptions"
                  :series="unluckySeries"
                  height="230"
                ></apexchart>
              </div>
              <div style="pointer-events: none">
                <div
                  class="px-3 py-1 colorBlack"
                  style="border-bottom: 1px solid #0a91f0"
                >
                  {{ $t("result_unlucky_info_n_year_img", [currentYear]) }}
                </div>
                <apexchart
                  type="area"
                  style="margin-bottom: -15px"
                  :options="chartOptions"
                  :series="series"
                  height="230"
                ></apexchart>
              </div>
            </div>
            <div style="text-align: center; display: flex; width: 100%">
              <div
                v-for="(qi, index) in qis"
                :key="index"
                :style="`width:${100 / qis.length}%;`"
              >
                <louyi-tool-tip
                  :content="getQiRange(currentYear, index)"
                  theme="light"
                >
                  <div
                    slot="label"
                    class="content colorMain button clickScale py-2"
                    style="text-decoration-line: underline; cursor: help"
                  >
                    {{ qi }}
                  </div>
                </louyi-tool-tip>
              </div>
            </div>
            <div class="my-3 content">
              <Collapse class="w-100 cleanCollapse">
                <Panel name="what_is_unlucky button">
                  <div style="display: flex; align-items: center">
                    <div class="w-0 button" style="flex-grow: 1">
                      {{ $t("result_unlucky_info_what") }}
                    </div>
                    <Icon class="myarrow" type="ios-arrow-forward" />
                  </div>
                  <div slot="content" class="colorMain">
                    <div
                      style="white-space: pre-wrap"
                      v-html="$t('result_unlucky_info_description')"
                    ></div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div
            v-else
            slot="content"
            class="p-5 row justify-content-center align-items-center"
            style="flex-flow: column; text-align: center"
          >
            {{ $t("result_fetch_field") }}
            <div
              class="button2"
              style="width: 200px"
              onclick="location.reload()"
            >
              {{ $t("result_fetch_retry") }}
            </div>
          </div>
        </Panel>
        <Panel
          name="extra_info"
          v-if="extraInfo && Object.keys(extraInfo).length > 0"
        >
          <label class="textSeparate button">{{
            $t("result_subject_extra_info")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <div slot="content" class="p-3 colorMain border-radius">
            <div v-for="(id, index) in Object.keys(extraInfo)" :key="id">
              <divider v-if="index > 0" class="my-3" />
              <field-value
                :field="$t(`result_subject_${id}`)"
                :value="extraInfo[id]"
              />
            </div>
          </div>
        </Panel>
        <Panel
          name="congenital_constitution"
          v-if="showInfo('congenital_constitution')"
        >
          <label class="textSeparate button">{{
            $t("result_subject_congenital_constitution")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardCongenitalConstitution
            slot="content"
            :record="records[recordIndex]"
          />
        </Panel>
        <Panel
          name="constitution"
          v-if="showInfo('constitution') || showInfo('constitution_detail')"
        >
          <label class="textSeparate button">{{
            $t("result_subject_constitution")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardConstitution
            slot="content"
            :record="records[recordIndex]"
            :showDetail="showInfo('constitution_detail')"
          />
        </Panel>
        <Panel name="mix_constitution" v-if="showInfo('mix_constitution')">
          <label class="textSeparate button">{{
            $t("result_subject_mix_constitution")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardMixConstitution slot="content" :record="records[recordIndex]" />
        </Panel>

        <Panel name="most_sick" v-if="showInfo('most_sick')">
          <label class="textSeparate button">{{
            $t("result_subject_most_sick")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardMostSick slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel name="second_sick" v-if="showInfo('second_sick')">
          <label class="textSeparate button">{{
            $t("result_subject_second_sick")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardSecondSick slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel name="hid_sick" v-if="showInfo('hid_sick')">
          <label class="textSeparate button">{{
            $t("result_subject_hid_sick")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardHidSick slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('bad_food')" name="bad_food">
          <label class="textSeparate button">{{
            $t("result_subject_bad_food")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardBadFood slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('good_food')" name="good_food">
          <label class="textSeparate button">{{
            $t("result_subject_good_food")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardGoodFood slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('drink')" name="drink">
          <label class="textSeparate button">{{
            $t("result_subject_drink")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardDrink slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('recipe')" name="recipe">
          <label class="textSeparate button">{{
            $t("result_subject_recipe")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardRecipe slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('acupoint')" name="acupoint">
          <label class="textSeparate button">{{
            $t("result_subject_acupoint")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardAcupoint slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('exercise')" name="exercise">
          <label class="textSeparate button">{{
            $t("result_subject_exercise")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardExercise slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('other')" name="other">
          <label class="textSeparate button">{{
            $t("result_subject_other")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardOther slot="content" :record="records[recordIndex]" />
        </Panel>
        <Panel v-if="showInfo('recommand')" name="other">
          <label class="textSeparate button">{{
            $t("result_subject_recommand")
          }}</label>
          <Icon class="myarrow" type="ios-arrow-forward" />
          <CardRecommand slot="content" :record="records[recordIndex]" />
        </Panel>
      </Collapse>
    </div>
    <div
      class="m-5"
      style="
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="px-2">{{ $t("result_share") }}</div>
      <!-- Facebook -->
      <div class="mx-2 button clickScale" style="width: 30px">
        <img src="/img/share_fb.png" @click="share('fb')" />
      </div>

      <!-- line -->
      <div class="mx-2 button clickScale" style="width: 30px">
        <img src="/img/share_line.png" @click="share('line')" />
      </div>
    </div>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        class="button1"
        :disabled="!canTest"
        @click="$router.push({ name: 'BeforeStart' })"
      >
        {{ $t("result_retest") }}
      </div>
    </div>
    <div
      class="pt-2 pb-5 colorMain subTitle"
      style="width: 100%; text-align: center"
    >
      {{ retestTimeText }}
    </div>
  </div>
</template>

<script>
import solarLunar from "solarlunar";
import VueApexCharts from "vue-apexcharts";
import UserInfoApiHelper from "../../script/Utility/UserInfoApiHelper";
import LouyiToolTip from "../component/LouyiToolTip.vue";
import FieldValue from "../component/FieldValue.vue";
export default {
  components: {
    apexchart: VueApexCharts,
    LouyiToolTip,
    FieldValue,
  },
  mounted() {
    window.solarLunar = solarLunar;
    const basicInfo = this.$route.params.basicInfo;
    const source = this.$route.params.source;
    if (basicInfo && basicInfo.records && basicInfo.records.length > 0) {
      this.setRecordBasicInfo(basicInfo.records);
    } else {
      loading();
      UserInfoApiHelper.getRecords({ source })
        .then((data) => {
          loading(false);
          this.setRecordBasicInfo(data);
        })
        .catch(errorHandler);
    }
    if (basicInfo) {
      this.setBasicInfo(basicInfo);
    } else {
      this.basicInfoLoading = true;
      UserInfoApiHelper.getBasicInfo({ source })
        .then((info) => {
          this.basicInfoLoading = false;
          this.setBasicInfo(info);
        })
        .catch((e) => {
          this.basicInfoLoading = false;
          errorHandler(e);
        });
    }
  },
  data() {
    const qis = [
      this.$t("result_qi_1"),
      this.$t("result_qi_2"),
      this.$t("result_qi_3"),
      this.$t("result_qi_4"),
      this.$t("result_qi_5"),
      this.$t("result_qi_6"),
    ];
    const defaultChartOptions = {
      colors: ["black"],
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0,
          opacityTo: 0,
        },
      },
      markers: {
        colors: ["#FF5E5E"],
        size: 5,
        strokeWidth: 0,
        discrete: [],
      },
      stroke: {
        width: 1,
        curve: "straight",
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: qis,
        tickPlacement: "between",
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        reversed: true,
        min: 0,
        max: 10,
      },
      grid: {
        strokeDashArray: 3,
        borderColor: "#0A91F055",
        padding: {
          left: -10, // or whatever value that works
          right: 0, // or whatever value that works
          bottom: -20,
        },
      },
      annotations: {
        position: "back",
        yaxis: [
          {
            y: 10,
            y2: 8,
            fillColor: "#FF999D",
          },
          {
            y: 7.95,
            y2: 8.05,
            fillColor: "#ff4a4a",
            opacity: 1,
          },
        ],
      },
    };
    const unluckyChartOptions = Object.assign({}, defaultChartOptions);
    unluckyChartOptions.markers = Object.assign(
      {},
      unluckyChartOptions.markers
    );
    unluckyChartOptions.xaxis = Object.assign({}, unluckyChartOptions.xaxis);
    unluckyChartOptions.xaxis.labels = { show: false };
    unluckyChartOptions.markers.size = 3;
    unluckyChartOptions.markers.discrete = [];
    return {
      extraInfo: null,
      qis,
      recordIndex: 0,
      retestTimeText: "",
      currentYear: -1,
      yearsData: {},
      basicInfo: {
        gender: -1,
        blood_type: "",
        solar_birth: "",
        lunar_birth: "",
        bhour: "",
        mobile: "",
        mobile_country_code: "",
        email: "",
      },
      records: [],
      basicInfoLoading: false,
      showMenu: false,
      company: this.$route.params.source.toUpperCase(),
      openPanel: [
        "unlucky_info",
        "constitution",
        "other",
        "exercise",
        "acupoint",
        "recipe",
        "drink",
        "good_food",
        "bad_food",
      ],
      series: [],
      unluckySeries: [],
      unluckyChartOptions: unluckyChartOptions,
      chartOptions: defaultChartOptions,
    };
  },
  computed: {
    unluckyYears() {
      return Object.keys(this.records[this.recordIndex].unlucky_info);
    },
    canTest() {
      const skipSources = process.env.MIX_SKIP_SOURCES?.split(",") ?? [];
      const source = this.$route.params.source;
      let testTimes = this.records.length;
      if (
        this.basicInfo.mobile == "0900000000" ||
        skipSources.includes(source)
      ) {
        this.retestTimeText = "您有無限次測試機會";
        return true;
      } else if (testTimes >= 2) {
        const testDate = new Date(this.records[0].timestamp.split(" ")[0]);
        testDate.setMonth(testDate.getMonth() + 3);
        if (Date.now() <= testDate) {
          this.retestTimeText = this.$t("result_retest_time", [
            testDate.getFullYear(),
            testDate.getMonth() + 1,
            testDate.getDate(),
          ]);
        } else {
          this.retestTimeText = this.$t("result_retest_hint");
        }
        return Date.now() > testDate;
      } else if (testTimes == 1) {
        this.retestTimeText = this.$t("result_retest_hint");
        return true;
      } else {
        return testTimes != 0;
      }
    },
  },
  beforeDestroy() {
    this.$emit("closeMenu");
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
    showInfo(key) {
      return (
        this.records.length > 0 &&
        this.records[this.recordIndex] &&
        !!Object.keys(this.records[this.recordIndex]).find(
          (recordKey) => recordKey == key
        )
      );
    },
    setRecordBasicInfo(records) {
      if (!this.extraInfo) {
        const source = this.$route.params.source;
        UserInfoApiHelper.getExtraInfo({ source }).then((info) => {
          this.extraInfo = info;
        });
      }
      this.records = records;
      if (records[this.recordIndex]) {
        const record = records[this.recordIndex];
        this.basicInfo.blood_type = record.blood_type;
        this.basicInfo.solar_birth = record.solar_birth?.DateFormat();
        this.basicInfo.lunar_birth = record.lunar_birth?.DateFormat();
        this.basicInfo.bhour = record.bhour;
        this.basicInfo.gender = record.gender;
        this.basicInfo.isleap = record.isleap;
        if (record.unlucky_info) {
          this.yearsData = record.unlucky_info || {};
          this.setUnluckyYearInfo();
        }
        setTimeout(() => {
          this.openPanel = ["unlucky_info", "constitution"];
          if (
            !this.showInfo("unlucky_info") &&
            !this.showInfo("unlucky_info_3year") &&
            !this.showInfo("unlucky_info_5year") &&
            !this.showInfo("constitution") &&
            !this.showInfo("constitution_detail")
          ) {
            this.openPanel.push("basic");
          }
        }, 100);
      }
    },
    setBasicInfo(basicInfo) {
      this.basicInfo.email = basicInfo.email || "";
      this.basicInfo.mobile = basicInfo.mobile || "";
      this.basicInfo.mobile_country_code = basicInfo.mobile_country_code || "";
      this.basicInfo.tel = basicInfo.tel || "";
      this.basicInfo.name = basicInfo.name || "";
    },
    selectYear(year) {
      this.currentYear = year;
      this.series = [
        {
          type: "area",
          data: [],
        },
      ];
      this.chartOptions.markers.discrete.length = 0;
      const values = this.yearsData[year];
      values.forEach((value) => {
        this.series[0].data.push(value);
        if (value < 8) {
          this.chartOptions.markers.discrete.push({
            fillColor: "#0A91F0",
            seriesIndex: 0,
            dataPointIndex: this.series[0].data.length - 1,
            size: 5,
          });
        }
      });
    },
    setUnluckyYearInfo() {
      if (Object.keys(this.yearsData).length > 0) {
        this.unluckySeries = [
          {
            type: "area",
            data: [],
          },
        ];
        const maxYear = Math.max(Object.keys(this.yearsData));
        let currentYear = new Date().getFullYear();
        currentYear = currentYear > maxYear ? maxYear : currentYear;
        Object.keys(this.yearsData).forEach((year) => {
          const values = this.yearsData[year];
          if (year == currentYear) {
            this.selectYear(year);
          }
          values.forEach((value) => {
            this.unluckySeries[0].data.push(value);
            if (value < 8) {
              this.unluckyChartOptions.markers.discrete.push({
                seriesIndex: 0,
                dataPointIndex: this.unluckySeries[0].data.length - 1,
                size: 0,
              });
            }
          });
        });
      } else {
        alert(this.$t("common_alert_system_busy"));
      }
    },
    share(source) {
      const url = `${location.origin}/${this.company}`;
      const text = encodeURIComponent(this.$t("result_share_content"));
      switch (source) {
        case "fb":
          window.open(
            `https://www.facebook.com/sharer.php?u=${url}&quote=${text}`,
            "FB-share",
            "height=500, width=500"
          );
          break;
        case "line":
          window.open(
            `https://lineit.line.me/share/ui?url=${url}&text=${text}`,
            "Line-share",
            "height=500, width=500"
          );
          break;
      }
    },
    getQiRange(year, index) {
      const termStart = (3 + index * 4) % 24;
      const monthStart = (index + 1) * 2;
      const dateStart = solarLunar.getTerm(year, termStart);
      let yearEnd = parseInt(year);
      let termEnd = termStart + 3;
      let monthEnd = monthStart + 2;
      let dateEnd = solarLunar.getTerm(yearEnd, termEnd + 1) - 1;
      if (termEnd > 24) {
        yearEnd = yearEnd + 1;
        termEnd = termEnd % 24;
        monthEnd = monthEnd % 12;
        dateEnd = solarLunar.getTerm(yearEnd, termEnd + 1) - 1;
      }
      return `${year}/${monthStart}/${dateStart}~${yearEnd}/${monthEnd}/${dateEnd}`;
    },
  },
  watch: {
    "$i18n.locale"() {
      this.qis = [
        this.$t("result_qi_1"),
        this.$t("result_qi_2"),
        this.$t("result_qi_3"),
        this.$t("result_qi_4"),
        this.$t("result_qi_5"),
        this.$t("result_qi_6"),
      ];
    },
  },
};
</script>