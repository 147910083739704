<template>
  <div>
    <div v-if="basicInfo">
      <field-value
        class="py-2 border"
        :field="$t('basic_info_field_name')"
        :value="basicInfo.name"
        style="border-top: 0px !important"
      />
      <field-value
        class="py-2 border"
        :field="$t('basic_info_field_gender')"
        :value="genderText || ''"
      />
      <field-value
        class="py-2 border"
        :field="$t('basic_info_field_blood_type')"
        :value="bloodMap[basicInfo.blood_type] || ''"
      />
      <field-value
        class="py-2 border"
        :field="$t('basic_info_field_birth_solor')"
        :value="basicInfo.solar_birth || ''"
      />
      <field-value
        class="py-2 border"
        :field="$t('basic_info_field_birth_lunar')"
        :value="
          basicInfo.lunar_birth +
            (basicInfo.isleap ? $t('basic_info_field_isleap_in_solar') : '') ||
          ''
        "
      />
      <field-value
        class="py-2 border"
        :field="$t('basic_info_field_birth_hour')"
        :value="birthMap[basicInfo.bhour] || ''"
      />
      <field-value
        class="py-2 border"
        :field="$t('basic_info_mobile')"
        :value="`(+${basicInfo.mobile_country_code})${basicInfo.mobile}`"
      />
      <field-value
        class="py-2 border"
        field="E-Mail"
        :value="basicInfo.email || ''"
      />
    </div>
    <div v-else>{{ $t("common_loading") }}</div>
  </div>
</template>
<script>
import FieldValue from "../../component/FieldValue.vue";
export default {
  components: { FieldValue },
  props: {
    basicInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      birthMap: {
        0: this.$t("basic_info_field_birth_hour_select_0") + "(00:00-01:00)",
        1: this.$t("basic_info_field_birth_hour_select_1") + "(01:00-03:00)",
        3: this.$t("basic_info_field_birth_hour_select_3") + "(03:00-05:00)",
        5: this.$t("basic_info_field_birth_hour_select_5") + "(05:00-07:00)",
        7: this.$t("basic_info_field_birth_hour_select_7") + "(07:00-09:00)",
        9: this.$t("basic_info_field_birth_hour_select_9") + "(09:00-11:00)",
        11: this.$t("basic_info_field_birth_hour_select_11") + "(11:00-13:00)",
        13: this.$t("basic_info_field_birth_hour_select_13") + "(13:00-15:00)",
        15: this.$t("basic_info_field_birth_hour_select_15") + "(15:00-17:00)",
        17: this.$t("basic_info_field_birth_hour_select_17") + "(17:00-19:00)",
        19: this.$t("basic_info_field_birth_hour_select_19") + "(19:00-21:00)",
        21: this.$t("basic_info_field_birth_hour_select_21") + "(21:00-23:00)",
        23: this.$t("basic_info_field_birth_hour_select_23") + "(23:00-00:00)",
        NA: this.$t("basic_info_field_unknow"),
      },
      bloodMap: {
        A: this.$t("basic_info_field_blood_type_A"),
        B: this.$t("basic_info_field_blood_type_B"),
        O: this.$t("basic_info_field_blood_type_O"),
        AB: this.$t("basic_info_field_blood_type_AB"),
        NA: this.$t("basic_info_field_unknow"),
      },
    };
  },
  computed: {
    genderText() {
      switch (this.basicInfo.gender) {
        case "0":
          return this.$t("basic_info_field_gender_0");
        case "1":
          return this.$t("basic_info_field_gender_1");
      }
      return undefined;
    },
  },
  watch: {
    basicInfo(value) {
      console.log(value);
    },
  },
};
</script>
<style scoped>
.border {
  border-top: lightgray solid 1px !important;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>