<template>
  <div>
    <div class="p-4">
      <div class="py-5" style="margin: auto; width: 30vmin; max-width: 200px">
        <div><img src="/img/louyi_logo.png" /></div>
        <div class="py-3" style="width: 5vmin; margin: auto">
          <img src="/img/cross.png" />
        </div>
        <div>
          <img :src="businessImg" />
        </div>
      </div>
      <div class="py-3">
        <div
          class="my-2 radius-8"
          style="display: flex; flex-flow: wrap; justify-content: center"
        >
          <PhoneNumberInput
            class="mr-1 mb-1"
            style="flex-grow: 1"
            v-model="params.mobileData"
            position="top"
            @keyup.enter.native="
              sendToken();
              $event.target.blur();
            "
          />
          <div
            class="button2"
            style="margin: 0 0 0 auto"
            @click="sendToken()"
            :disabled="!params.mobileData.success || timeLeft != 0"
          >
            {{ sendTokenText }}
          </div>
        </div>

        <Input
          class="my-2 radius-8"
          v-model="params.token"
          :placeholder="$t('verify_field_mobile_token_hint')"
          :maxlength="6"
          @keyup.enter.native="
            submit();
            $event.target.blur();
          "
        />
        <div>
          <div class="px-2 colorRed" style="display: flex; align-items: center">
            <checkbox-button
              v-model="accepts"
              tag="privacy_and_services"
              text=""
            />
            <label class="my-auto" for="privacy_and_services">{{
              $t("verify_field_agree")
            }}</label>
            <router-link class="colorRed" :to="{ name: 'PrivacyPolicy' }"
              >《<u>{{ $t("verify_field_privacy_policy") }}</u
              >》</router-link
            >
            <label class="my-auto" for="privacy_and_services">{{
              $t("verify_field_and")
            }}</label>
            <router-link class="colorRed" :to="{ name: 'Services' }"
              >《<u>{{ $t("verify_field_services") }}</u
              >》</router-link
            >
          </div>
        </div>
      </div>
      <div class="button1 my-5" @click="submit()">
        {{ $t("common_button_confirm") }}
      </div>
    </div>
  </div>
</template>

<script>
import LoginApiHelper from "../../script/Utility/LoginApiHelper";
import UserInfoApiHelper from "../../script/Utility/UserInfoApiHelper";
import CheckboxButton from "../component/CheckboxButton.vue";
export default {
  components: { CheckboxButton },
  mounted() {
    const activeCode = this.$route.query.a;
    if (activeCode) {
      this.$route.query.a = "";
      history.replaceState(null, null, location.href.split("?")[0]);
      this.$router.push({ name: "ActiveAccount", params: { activeCode } });
    } else {
      const source = this.$route.params.source;
      loading();
      UserInfoApiHelper.getUserData({ source })
        .then((basicInfo) => {
          loading(false);
          this.goNext(basicInfo);
        })
        .catch(() => {
          loading(false);
        });
    }
  },
  beforeDestroy() {
    // this.$root.$off("enter", this.submit);
  },
  data() {
    window.self = this;
    const source = this.$route.params.source;
    const params = getCookie("verify") || {};
    return {
      accepts: [],
      timeLeft: 0,
      waitTime: 60,
      params: {
        source,
        token: params.token || "",
        mobileData: params.mobileData || {
          mobile_country_code: "",
          mobile: "",
          success: false,
        },
      },
    };
  },
  computed: {
    sendTokenText() {
      return this.timeLeft
        ? `${this.timeLeft} s`
        : this.$t("verify_button_token_send");
    },
    businessImg() {
      return `${
        location.origin
      }/Console/storage/images/logos/${this.params.source.toUpperCase()}.png`;
    },
  },
  methods: {
    submit: function () {
      if (!this.params.token) {
        alert(this.$t("verify_field_mobile_token_hint"));
        return;
      }
      if (this.accepts.indexOf("privacy_and_services") < 0) {
        alert(this.$t("verify_alert_agree"));
        return;
      }
      loading();
      const params = {
        mobile: this.params.mobileData.mobile,
        mobile_country_code: this.params.mobileData.mobile_country_code,
        verify_code: this.params.token,
        source: this.params.source,
      };
      // 驗證驗證碼
      LoginApiHelper.verifyMobile(params)
        .then((basicInfo) => {
          loading(false);
          toast(this.$t("verify_alert_token_success"));
          this.goNext(basicInfo);
        })
        .catch(errorHandler);
    },
    sendToken() {
      if (!this.params.mobileData.mobile) {
        alert(this.$t("verify_field_mobile_hint"));
        return;
      }
      if (!this.params.mobileData.success) {
        alert(this.$t("edit_basic_info_field_mobile_format_error"));
        return;
      }
      if (this.timeLeft == 0) {
        const params = {
          mobile: this.params.mobileData.mobile,
          mobile_country_code: this.params.mobileData.mobile_country_code,
          source: this.params.source,
        };
        LoginApiHelper.sendVerifyMobile(params).catch(errorHandler);
        const startTime = new Date();
        const intervel = setInterval(() => {
          const timeLeft = Math.floor(
            this.waitTime - (new Date() - startTime) / 1000
          );
          if (timeLeft > 0) {
            this.timeLeft = timeLeft;
          } else {
            this.timeLeft = 0;
            clearInterval(intervel);
          }
        }, 300);
      }
    },
    goNext(basicInfo) {
      delete this.params.token;
      delete this.params.mobileData.success;
      setCookie("verify", this.params);
      if (basicInfo.need_active) {
        this.$router.push({ name: "ActiveAccount", params: { basicInfo } });
      } else {
        if (basicInfo.records.length > 0) {
          this.$router.push({ name: "Result", params: { basicInfo } });
        } else {
          this.$router.push({ name: "BeforeStart", params: { basicInfo } });
        }
      }
    },
  },
  watch: {
    params: {
      handler: (value) => {
        const verify = copy(value);
        delete verify.mobileData.success;
        setCookie("verify", verify);
      },
      deep: true,
    },
  },
};
</script>