<template>
  <div class="border-radius p-2">
    <div class="subTitle bold">
      {{ $t("result_subject_recommand_product") }}
    </div>
    <div style="display: flex; justify-content: center">
      <div v-for="recommand in record.recommand" :key="recommand.id">
        <a :href="recommand.link" target="_blank">
          <img
            :src="recommand.image"
            style="max-height: 200px; width: auto !important"
          />
          <div style="text-align: center">{{ recommand.name }}</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getContent(content) {
      if (content.indexOf("http") == 0) {
        return `<a href="${content}" target="_blank">${content}</a>`;
      }
      return content;
    },
  },
};
</script>